//import React, { useEffect, useState } from 'react'
import React from 'react'
import { Container,Row,Col} from 'react-bootstrap'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// import arrowlink from '../images/arrow-link-hbg.webp'
import { NavLink } from 'react-router-dom'
// import blog1 from '../images/blog1.webp'
import arrowlink from '../images/arrow-link-hbg.webp'
import './singlebody.css'
import PostFrom from './PostForm';



const Singlebody = (props) => {
   
      const scrollToTop = () => {
        props.setBlank(true);
        setTimeout(() => {
            ScrollTrigger.refresh();
            window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
        props.setBlank(false);            
        }, 700);
        // ScrollTrigger.refresh();
      }

    const relatedPost = props.relatedPost;
    gsap.registerPlugin(ScrollTrigger);

    return (
        <>
          {/* content section starts */}
            <section className='benefit-section mt-lg-5 pt-lg-5 mt-3 pt-3'>
                <Container className='mb-5 pb-5'>
                    <Row>
                        {/* {ID, post_title, thumbnail, auther_name, post_date, post_content} */}
                        {/* <div className='benefit-section-para' dangerouslySetInnerHTML={{__html:props.data.post_content}} ></div> */}
                        <div className='benefit-section-para' dangerouslySetInnerHTML={{__html:props.content}} ></div>
                    </Row>
                </Container>
                {/* content section ends */}
            </section>

            {(props.id === "324") ? 
                <PostFrom  title={props.data.post_title} />
            : ""
            }



             {/* Related blog section */}
            <section className='benefit-blog-section'>
               
              <Container>
                <Row className='blog-section-div'>
                    <Col className='col-md-6'>
                        <h3 className='blogheading'>KEEP READING..</h3>
                    </Col>
                    <Col className='col-md-6'>
                        <NavLink to='/insights' onClick={scrollToTop} ><h5 className='viewbtn'>VIEW ALL</h5></NavLink>
                    </Col>
                </Row>

        
                 <Row className='pb-5'>
                    <Col className='overflow-x-mb'>
                    <div className="related-blog pb-mb-5">

                       {relatedPost.map((post) => {

                        return (
                                <div key={post.ID} className="single-blog">
                                    <div className="d-flex gap-x-30">
                                    <NavLink onClick={scrollToTop} to={`/single/${post.ID}`} >
                                    <div className="blog-content"><img src={post.thumbnail} alt='' />
                                        <h2 className='mt-3' dangerouslySetInnerHTML={{__html:post.post_title}} ></h2> 
                                        <p className='mt-2'>by {post.auther_name}   -    {post.post_date}</p>
                                    </div>
                                    </NavLink>
                                    <div className="overlay-box"><img src={`${arrowlink}`} alt='' />
                                        <svg className="green-bg" width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.28654 0.5L38.8965 32.4416C30.1543 41.2472 15.9904 41.2395 7.26353 32.4184C-1.46334 23.5973 -1.45568 9.3056 7.28654 0.5Z" fill="#B8E819"/>
                                        </svg>
                                    </div>
                                    </div>
                                </div>                     
                            );
                        })}    

                    </div>
                    </Col>
                </Row>                 
            
             </Container>
            </section>
              {/* Related blog section */}
        </>
    )
}

export default Singlebody