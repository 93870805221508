import React, { useState } from 'react'
import { Row, Col, Container} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


import './hovertab.css'
gsap.registerPlugin(ScrollTrigger);

const HoverTab = (props) => {

  
    const scrollToTop = () => {
        props.setBlank(true);
        setTimeout(() => {
            ScrollTrigger.refresh();
            window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
        props.setBlank(false);            
        }, 500);
    }

    const [activeTab, setActiveTab] = useState("tab1");

    const data = props.data;


    return (
        <>

            <section className='hovertab-section' >
                {/* hover tab desktop section */}
                <Container fluid className={props.class !== undefined ? `px-5p desktop ${props.class}` : "px-5p desktop"} >
                    <h3 className="heading">{props.title !== "" ? props.title : ""} </h3>
                    <Row className='middle'>

                        <Col className='col-md-6 left-side'>
                            <div className="hover-tabs tab">

                                {data.map((d, index, array) => {
                                    return (
                                            <div key={d.id} className={activeTab === d.tabname ? "tablinks active" : "tablinks"} onMouseOver={() => { setActiveTab(d.tabname) }}>
                                                <div className="d-flex tab-box ">
                                                    <div className=" tabimg py-4">
                                                        {activeTab === d.tabname ? <img src={d.tabhoverimg} alt="..." /> : <img src={d.tabimg} alt="..." />}
                                                    </div>
                                                    <div className={index === array.length -1 ? "tab-text py-4 ms-5 border-bottom-0" : "tab-text py-4 ms-5" } >
                                                        <h3 className='tab-heading pt-2'>{d.tabheading}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                    )
                                })
                                }

                            </div>

                        </Col>

                        <Col className='col-md-6 right-side'>


                            <div className='content-box'>
                
                                {data.map((d) => {
                                    return (
                                            <div key={d.id} className={activeTab === d.tabname ? "tabcontent active" : "tabcontent"}>
                                                <img src={d.tabcontentimg} alt="..." />
                                            </div>
                                    )
                                })
                                }

                            </div>
                        </Col>


                    </Row>

                
                    {props.button !== undefined ? <div className='text-center'><NavLink onClick={scrollToTop} to='/apply-to-join'><button className="applynow-btn" >APPLY TO JOIN </button></NavLink></div> : ""}

                </Container>
                  {/* hover tab desktop section ends */}

                {/*  hover tab section (memberbenefits) start */}
                <Container fluid className={props.class === "memberbenefits" ? `px-5p mobile ${props.class}` : "px-5p d-none"} >
                    <h3 className="heading">{props.title !== "" ? props.title : ""} </h3>
                    <div className='d-flex '>


                        {data.map((d) => {
                            return (
                                    <Col key={d.id} className='col-md-12 w-100 mx-auto py-4'>
                                        <div className="mobileviewhover text-center">
                                           <div className='pb-4 borderbottom'>
                                            <div>
                                           <img className='w-100' src={d.tabcontentimg} alt="..." /></div>
                                            <h3 className='imgheading text-start'>{d.imgcaption}</h3>
                                           </div>
                                        </div>
                                    </Col>
                            )
                        })
                        }

                    </div>

                    {/* {props.button !== undefined ? <img src={d.tabhoverimg} alt="..." /> : <img src={d.tabimg} alt="..." /> } */}

                    {props.button !== undefined ? <div className='text-center'><NavLink onClick={scrollToTop} to='/apply-to-join'><button className="applynow-btn" >APPLY TO JOIN </button></NavLink></div> : ""}

                </Container>
                {/*  hover tab section (memberbenefits) Ends */}

                {/*  hover tab section (ex-memberbenefits) start */}
                <Container fluid className={props.class === "ex-memberbenefits" ? `px-5p mobile ${props.class}` : "px-5p d-none"} >
                    <h3 className="heading mobile-view-heading">EXCLUSIVE <br/> MEMBERSHIP BENEFITS</h3>
                    <Row className='my-4'>

                        <Col className='mx-auto left-side'>
                            <div className="hover-tabs tab mt-4">

                                {data.map((d) => {
                                    return (
                                            <div className="mb-4 mt-2" key={d.id} >
                                                <div className="text-center">
                                                    <div className="tabimg ">
                                                        {/* {activeTab === d.tabname ? <img src={d.tabhoverimg} alt="..." /> : <img src={d.tabhoverimg} alt="..." />} */}
                                                        
                                                        { (d.tabhoverimgmb) ? <img src={d.tabhoverimgmb} alt="..." /> : <img src={d.tabhoverimg} alt="..." />}
 
                                                        {/* <img src={d.tabhoverimg} alt="..." />  */}
                                                    </div>
                                                    <div className="tab-text mt-3 py-1">
                                                        <h3 className='tab-heading'>{d.tabheading}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                    )
                                })
                                }


                            </div>

                        </Col>


                    </Row>


                    {props.button !== undefined ? <div className='text-center'><NavLink onClick={scrollToTop} to="/apply-to-join"><button className="applynow-btn" >APPLY TO JOIN </button></NavLink></div> : ""}

                </Container>
               {/*  hover tab section (ex-memberbenefits) start */}                     


            </section>

        </>

    )
}

export default HoverTab