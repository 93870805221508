import React from 'react'
import { Row, Col, Container} from 'react-bootstrap';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './meet.css';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { NavLink } from "react-router-dom";


import img_redirect from '../images/redirect.webp';
import img1 from '../images/BANGKOK.webp';
import img2 from '../images/KUALA.webp';
import img3 from '../images/JAKARTA.webp';
import down from "../images/down.webp";


gsap.registerPlugin(ScrollTrigger);

const Meet = ({setBlank}) => {
 

  function MouseOver(event) {

    const imgexpands = document.querySelectorAll(".img-expand1");


    for (let i = 0; i < imgexpands.length; i++) {

      var containerTrigger = imgexpands[i].closest('.img-expand');

      gsap.to(containerTrigger, {
        // width:'30%',
        className: "img-expand",
        // delay:0.1,
        duration: 0,
        // ease: "power1.in"
      });

    }

    // event.target.parentElement.style.width = '60%';
    containerTrigger = event.target.closest('.img-expand');
    gsap.to(event.target.closest('.img-expand'), {
      // width:'60%',
      className: "img-expand full",
      // delay:0.1,
      duration: 0,
      // ease: "power1.in"
    });
  }

  const scrollToTop = () => {
    setBlank(true);
    setTimeout(() => {
      ScrollTrigger.refresh();
      window.scrollTo(0, 0);
    }, 100);
    setTimeout(() => {
      setBlank(false);            
    }, 700);
  }


  return (
    <section className='meet-section' >
      {/* meet section desktop start */}
      <Container fluid className='px-5p desktop'>
        <Row className=''>
          <Col className='col-md-5'>
            <h3 className="heading mobile-view-heading">PUBCLUB  MEETS</h3>
          </Col>

          <Col className='col-md-7'>

            <div className="img-container me-auto">

              <div className='img-expand full' >
              <NavLink to="/single/285">  
                <div className="overlay" onMouseOver={MouseOver}></div>
              </NavLink>
                <img src={img1} className="img-expand1" onMouseOver={MouseOver} alt='' />
                <div className='arrow'><NavLink onClick={scrollToTop} to="/single/285"><img src={img_redirect} alt='' /></NavLink></div>
                <div className='text'>
                  <div className='head1'>BANGKOK</div>
                  <div className='head2'>27th Jan</div>
                </div>
              </div>

              <div className='img-expand '>
              <NavLink to="/single/301">  
              <div className="overlay" onMouseOver={MouseOver}></div>
              </NavLink>
                <img src={img2} className="img-expand1" onMouseOver={MouseOver} alt='' />
                <div className='arrow'><a onClick={scrollToTop} href="/single/301"><img src={img_redirect} alt='' /></a></div>
                <div className='text'>
                  <div className='head1'>KUALA LUMPUR</div>
                  <div className='head2'>9th Feb</div>
                </div>
              </div>

              <div className='img-expand ' >
              <NavLink to="/single/295">  
              <div className="overlay" onMouseOver={MouseOver}></div>
              </NavLink>
                <img src={img3} className="img-expand1" alt='' onMouseOver={MouseOver} />
                <div className='arrow'><a onClick={scrollToTop} href="/single/295"><img src={img_redirect} alt='' /></a></div>
                <div className='text' onMouseOver={MouseOver} >
                  <div className='head1'>JAKARTA</div>
                  <div className='head2'>2nd Feb</div>
                </div>

              </div>

            </div>

          </Col>
        </Row>
      </Container>
      {/* meet section desktop ends */}


      {/* meet section mobile starts */}
 

      <Container fluid className=' mobile'>
        <Row className=''>
          <Col className='col-md-12'>
            <h3 className="heading mobile-view-heading">PUBCLUB  MEETS</h3>
          </Col>
        </Row>
        <Row>
          <Col className='col-md-12'>

            {/* Code for  slider */}
            <div className='meet-slider'>
              <Swiper
                slidesPerView={"auto"}
                centeredSlides={true}
                spaceBetween={20}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className='mobileswiper' >
                    <div className='swiper-div'>
                      <img src={img1} alt='' />
                      <div className="overlay"></div>
                      <div className='arrow'><a onClick={scrollToTop} href="/single/285"><img src={img_redirect} alt='' /></a></div>
             
                    </div>

                    <div className='text' onMouseOver={MouseOver} >
                      <div className='head1'>BANGKOK</div>
                      <div className='head2'>27th Jan</div>

                    </div>
                  </div>


                </SwiperSlide>
                <SwiperSlide>
                <div className='mobileswiper' >
                    <div className='swiper-div'>
                      <img src={img2} alt='' /> 
                      <div className="overlay"></div>
                      <div className='arrow'><a onClick={scrollToTop} href="/single/301"><img src={img_redirect} alt='' /></a></div>
 
                    </div>
                    <div className='text' onMouseOver={MouseOver} >
                      <div className='head1'>KUALA LUMPUR</div>
                      <div className='head2'>9th Feb</div>

                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className='mobileswiper' >
                    <div className='swiper-div'>
                      <img src={img3} alt='' />
                      <div className="overlay"></div>
                      <div className='arrow'><a onClick={scrollToTop} href="/single/295"><img src={img_redirect} alt='' /></a></div>
 
                    </div>
                    <div className='text' onMouseOver={MouseOver} >
                      <div className='head1'>JAKARTA</div>
                      <div className='head2'>2nd Feb</div>

                    </div>
                  </div>
                </SwiperSlide>

              </Swiper>
            </div>       
          </Col>

        </Row>
      </Container>
      {/* meet section mobile starts */}

      {/* want to know more mobile section of home page starts */}
      <Container className='mobileview-footer mb-4 pt-4'>
        <Row>
          <Col className='mx-auto text-center mt-5'>
            <div className='footer-heading'>
              <h3>WANT  T<span><img src={down} alt='' style={{ transform: "rotate(180deg) translateY(50%)" }} /></span></h3>
              <h3>KN<span><img src={down} alt='' style={{ transform: "rotate(180deg) translateY(50%)" }} /></span>W M<span><img src={down} alt='' /></span>RE?</h3>
            </div>

            <NavLink to='/apply-to-join' onClick={scrollToTop}>  <button className="contact-btn mt-50">CONTACT US</button></NavLink>
          </Col>
        </Row>
      </Container>
      {/* want to know more mobile section of home page ends */}

    </section>
  )
}

export default Meet